import React, { useEffect, useState } from 'react';
import useContext from '../../hooks/useContext';
import * as S from './Stage.styles';
import Slider from '../slider/Slider';
import { collections } from '../../axios';
import { Container } from 'reactstrap';

const Stage = () => {
  const { currentPage } = useContext();
  const currentPageStage = currentPage.stageBackground;
  const [images, setImages] = useState(null);

  useEffect(() => {
    const fetchStage = async () => {
      const { data: fetchedImages } = await collections.get('/get/StageSlider');

      if (currentPageStage && currentPageStage.length > 0) {
        setImages(
          fetchedImages.filter((imageItem) => {
            return currentPageStage.find(
              (currentPageStageItem) => currentPageStageItem._id === imageItem._id
            );
          })
        );
      } else {
        setImages(fetchedImages);
      }
    };

    fetchStage();
  }, []);

  const sliderSettings = {
    autoplay: true,
  };

  return (
    <S.Wrapper>
      <Container>
        <S.Stage>
          <S.StageText>
            <h1>{currentPage.stageText}</h1>
          </S.StageText>
          <S.StageImage>
            <Slider items={images} settings={sliderSettings} />
          </S.StageImage>
        </S.Stage>
      </Container>
    </S.Wrapper>
  );
};

export default Stage;
