import styled from '@emotion/styled';

export const Slider = styled.div`
  .slick-slider {
    svg {
      color: ${({ theme, color }) => color || theme.colors.primary};
      width: 40px;
      height: 40px;
    }

    .slick-prev {
      top: -32px;
      left: -12px;
    }

    .slick-next {
      top: -32px;
      right: -12px;
    }
  }
  .slick-list {
    transition: height 0.2s;
  }
`;

export const SliderCounter = styled.p`
  text-align: center;
  width: 100%;
  font-weight: bold;
`;

export const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const Overlay = styled.div`
  padding: ${({ theme }) => theme.globals.spacing / 2}px;
  background-color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  bottom: 36px;
  right: 0;
  display: flex;
  align-items: center;
  height: auto;
  line-height: 1;
  z-index: 1;
  hyphens: auto;
  opacity: 80%;

  p {
    margin: 0;
    line-height: 1;
  }

  @media (max-width: 1000px) {
    right: auto;
    left: 0;
    bottom: 10px;
  }
`;
